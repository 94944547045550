import React from 'react';
import './Foot.scss';

function Foot(props) {
    const year = (new Date()).getFullYear();
    return (
        <footer className="Foot">
            <p className="moto">
                <span>Find</span> <span>New</span> <span>Roads</span>
            </p>
            <p className="copyright">{props.dealerFotter}</p>
            <p style={{marginTop:"0", fontSize:"0.75rem", textAlign:"right", color: "#393939", paddingRight: "0.625rem" }}>{year} - Todos os direitos reservados.</p>
        </footer>
    );
}

export default Foot;