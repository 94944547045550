import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import './App.scss';

import Head from './components/Head/Head';
import Foot from './components/Foot/Foot';
import Hero from './components/Hero/Hero';
import Menu from './components/Menu/Menu';

function getFilename() {
  let file = null;

  const useQueryHosts = [
    "127.0.0.1",
    "localhost",
    "rededigitalchevrolet.com.br"
  ];
  
  const hostname = document.location.hostname;

  const useQuery = useQueryHosts.indexOf(hostname) > -1 ? true : false;

  if (useQuery) {
    file = document.location.search.substring(8);
  } else {
    file = document.location.hostname.replace(/(www\.|\.com|\.br)/gm, "");
  }

  return `dealerData/${file}.json`;
  //return `dealerData/liderchevrolet.json`;
  
}

function App() {
  const fakeDealerData = {
    "name": "Concessionária",
    "logo": "empty.png",
    "stores": []
  }

  const [dealerData, setDealerData] = useState(fakeDealerData);
  

  useEffect(() => {
    axios.get(getFilename())
    .then(res => {
      setDealerData(res.data);
    });
    document.title = `Chevrolet - ${dealerData.name}`;
    if (dealerData.name === 'CVC'){
      
      document.title = `Concessionárias CVC | A sua Chevrolet no Espírito Santo`;
      document.querySelector('meta[name="description"]').setAttribute("content", "Na CVC Chevrolet você encontra carros novos, seminovos e opções para o seu negócio através de Vendas Diretas. Aproveite as ofertas, produtos e serviços automotivos");
      document.querySelector('meta[name="google-site-verification"]').setAttribute("content", "wA446xRL9Y-SifMWjEfctPQbpTlvoVzv4oqotohy0No");
    }
    else if (dealerData.name === 'Líder'){
      document.querySelector('meta[name="google-site-verification"]').setAttribute("content", "H3g_ein1AXl743Ls5mExBbJo8Mg2EdH0WxvtnpSpgmo");
    }

    
  });

  return (
    <div className="App">
      <Head dealerLogo={dealerData.logo} />
      <Hero />
      <Menu dealerStores={dealerData.stores} />
      <Foot dealerFotter={dealerData.footer} />
    </div>
  );
}

export default App;
