import React, { useState } from 'react';
import './Menu.scss';

function Menu(props) {
    const [isOpen, setIsOpen] = useState(false);

    const storeList = props.dealerStores.map((store) => {
        return (<a key={store.url+store.fullName} target="_top" href={store.url}>{store.fullName}</a>);
    })
    
    return (
        <nav className="Menu">
            <h2 onClick={()=>setIsOpen(!isOpen)}>Encontre a loja 
            {window.location.search.split("=")[1] === 'vessa' ? 
                <span className='vessa-white'> mais</span> : 
                " mais"
            } próxima</h2>
            <div className={`options ${(isOpen ? "open" : "closed")}`}>
                {storeList}
            </div>
        </nav>
    );
}

export default Menu;