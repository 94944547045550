import React from 'react';
import './Hero.scss';

function Hero() {
        switch (window.location.search.split("=")[1]) {
                case 'capri':
                    return (
                        <main className='Hero capri'>
                            <h1>NOSSAS OFERTAS ESTÃO <span>MAIS PERTO DE VOCÊ.</span></h1>
                        </main>
                    );
                    break;
                case 'vessa':
                    return (
                        <main className='Hero vessa'>
                            <h1>NOSSAS <span className="yellow">OFERTAS</span> ESTÃO <span>MAIS <span className="yellow">PERTO</span> DE VOCÊ.</span></h1>
                        </main>
                    );
                    break;
            
                default:
                    return (
                        <main className='Hero'>
                            <h1>NOSSAS OFERTAS ESTÃO <span>MAIS PERTO DE VOCÊ.</span></h1>
                        </main>
                    );
                    break;
            }
        }

export default Hero;