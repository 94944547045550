import React from 'react';
import './Head.scss';

import ChevLogo from '../../images/chevy.png';

function Head(props) {
    const dealerLogo = `dealerLogos/${props.dealerLogo}`;

    return (
        <header className="Head">
            <img src={ChevLogo} alt="Logo" className="logo logo__chev" />
            <img src={dealerLogo} alt="Logo" className="logo logo__dealer" />
        </header>
    );
}

export default Head;